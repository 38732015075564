@import 'styles/variables';
@import 'styles/mixins';

$spin-duration: 1.2s;

.pxw-icon {
  display: inline-block;
  line-height: 0;

  &--size-xs svg { width: $icon-xs; height: $icon-xs }
  &--size-sm svg { width: $icon-sm; height: $icon-sm }
  &--size-md svg { width: $icon-md; height: $icon-md }
  &--size-lg svg { width: $icon-lg; height: $icon-lg }

  &--color-inherit { color: inherit }
  &--color-blue { color: $color-blue }
  &--color-pink { color: $color-pink }
  &--color-yellow { color: $color-yellow }
  &--color-cyan { color: $color-cyan }
  &--color-theme1 { color: var(--theme1) }
  &--color-theme1-contrast { color: var(--theme1-contrast) }
  &--color-theme2 { color: var(--theme2) }
  &--color-theme2-contrast { color: var(--theme2-contrast) }
  &--color-success { color: $color-success }
  &--color-danger { color: $color-danger }
  &--color-warning { color: $color-warning }
  &--color-info { color: $color-info }
  &--color-white { color: $color-white }
  &--color-black { color: $color-black }

  &--spin {
    animation: icon-spin $spin-duration linear 0s infinite normal;
  }
  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }

  &[icon="arrow-down"] {
    transform: rotate(-90deg);
  }
  &[icon="arrow-up"] {
    transform: rotate(90deg);
  }
  &[icon="remove-circle"] {
    transform: rotate(45deg);
  }
  
  svg,
  path,
  rect {
    fill: currentColor !important;
    stroke: currentColor !important;
  }
  path:not([stroke]) {
    stroke-width: 0!important;
  }
  path[stroke],
  rect[stroke] {
    fill: none !important;
  }
}

@keyframes icon-spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
  }
}